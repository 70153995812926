<template>
  <div class="medicare-price-data-table text-left">
    <div class="font-bold text-lg text-center heading">{{ getTierDescription() }}</div>
    <DataTable :headers="headers" :data="data" data-testid="medicare-price-data-table">
      <template slot="table-header-renderer-id-1" slot-scope="{ header }">
        <TableHeaderLabel>
          <div class="text-center leading-6">
            {{ header.text }}
            <p class="header-small">{{ getDaysSupplyText() }}</p>
          </div>
        </TableHeaderLabel>
      </template>
      <template slot="table-header-renderer-id-2" slot-scope="{ header }">
        <TableHeaderLabel>
          <div class="text-center leading-6">
            {{ header.text }}
            <p class="header-small">{{ getDaysSupplyText() }}</p>
          </div>
        </TableHeaderLabel>
      </template>
      <template slot="cell-renderer-planName" slot-scope="{ rowData }">
        <TableDataCell class="font-bold leading-6">
          {{ rowData.planName }}
        </TableDataCell>
      </template>
      <template slot="cell-renderer-preferred" slot-scope="{ rowData }">
        <TableDataCell class="leading-6">
          <b>{{ getTierData(rowData).preferred }}</b> {{ getCopayText() }}
        </TableDataCell>
      </template>
      <template slot="cell-renderer-standard" slot-scope="{ rowData }">
        <TableDataCell class="leading-6">
          <b>{{ getTierData(rowData).standard }}</b> {{ getCopayText() }}
        </TableDataCell>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable, { TableHeaderLabel, useDefaultDataTableStyle } from "@/components/DataTable";
import TableDataCell from "@/components/DataTable/src/TableDataCell";

useDefaultDataTableStyle();

export default {
  name: "MedicareBlueAdvantagePriceDataTable",
  components: {
    TableDataCell,
    TableHeaderLabel,
    DataTable,
  },
  props: {
    selectedTier: {
      type: Number,
      required: true,
    },
    isSelectInsulin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          id: 0,
          text: "",
          associatedDataProperty: "planName",
          isSortable: false,
        },
        {
          id: 1,
          text: this.$t("MedicareBlueAdvantagePriceDataTable.PreferredRetailAndMailOrderPharmacy"),
          associatedDataProperty: "preferred",
          isSortable: false,
        },
        {
          id: 2,
          text: this.$t("MedicareBlueAdvantagePriceDataTable.StandardRetailPharmacy"),
          associatedDataProperty: "standard",
          isSortable: false,
        },
      ],
      data: [
        {
          planName: this.$t("MedicareBlueAdvantagePriceDataTable.SapphireAndGarnetPlan"),
          tier1: {
            preferred: "$0/$0",
            standard: "$6/$15",
          },
          tier2: {
            preferred: "$10/$10",
            standard: "$15/$35",
          },
          tier3: {
            preferred: "$42/$105",
            standard: "$47/$135",
          },
          tier4: {
            preferred: "50%",
            standard: "50%",
          },
          tier5: {
            preferred: "33%",
            standard: "33%",
          },
          tierSelectInsulin: {
            preferred: "$35/$105",
            standard: "$35/$105",
          },
        },
        {
          planName: this.$t("MedicareBlueAdvantagePriceDataTable.PrimePlan"),
          tier1: {
            preferred: "$0/$0",
            standard: "$6/$15",
          },
          tier2: {
            preferred: "$10/$10",
            standard: "$15/$35",
          },
          tier3: {
            preferred: "$42/$105",
            standard: "$47/$135",
          },
          tier4: {
            preferred: "50%",
            standard: "50%",
          },
          tier5: {
            preferred: "33%",
            standard: "33%",
          },
          tierSelectInsulin: {
            preferred: "$35/$105",
            standard: "$35/$105",
          },
        },
        {
          planName: this.$t("MedicareBlueAdvantagePriceDataTable.RubyPlan"),
          tier1: {
            preferred: "$0/$0",
            standard: "$6/$15",
          },
          tier2: {
            preferred: "$5/$5",
            standard: "$10/$25",
          },
          tier3: {
            preferred: "$28/$70",
            standard: "$33/$95",
          },
          tier4: {
            preferred: "50%",
            standard: "50%",
          },
          tier5: {
            preferred: "33%",
            standard: "33%",
          },
          tierSelectInsulin: {
            preferred: "$28/$70",
            standard: "$33/$95",
          },
        },
        {
          planName: this.$t("MedicareBlueAdvantagePriceDataTable.DiamondPlan"),
          tier1: {
            preferred: "$0/$0",
            standard: "$6/$15",
          },
          tier2: {
            preferred: "$5/$5",
            standard: "$10/$25",
          },
          tier3: {
            preferred: "$28/$70",
            standard: "$33/$95",
          },
          tier4: {
            preferred: "50%",
            standard: "50%",
          },
          tier5: {
            preferred: "33%",
            standard: "33%",
          },
          tierSelectInsulin: {
            preferred: "$28/$70",
            standard: "$33/$95",
          },
        },
      ],
    };
  },
  methods: {
    getTierData: function (rowData) {
      if (this.isSelectInsulin) {
        return rowData.tierSelectInsulin;
      }

      switch (this.selectedTier) {
        case 1:
          return rowData.tier1;
        case 2:
          return rowData.tier2;
        case 3:
          return rowData.tier3;
        case 4:
          return rowData.tier4;
        case 5:
          return rowData.tier5;
      }
    },
    getTierDescription: function () {
      if (this.isSelectInsulin) {
        return this.$t("MedicareBlueAdvantagePriceDataTable.SelectInsulinDrugsTier");
      }

      switch (this.selectedTier) {
        case 1:
          return this.$t("MedicareBlueAdvantagePriceDataTable.PreferredGenericTier");
        case 2:
          return this.$t("MedicareBlueAdvantagePriceDataTable.GenericTier");
        case 3:
          return this.$t("MedicareBlueAdvantagePriceDataTable.PreferredBrandDrugsTier");
        case 4:
          return this.$t("MedicareBlueAdvantagePriceDataTable.NonPreferredDrugsTier");
        case 5:
          return this.$t("MedicareBlueAdvantagePriceDataTable.SpecialtyTier");
      }
    },
    getDaysSupplyText: function () {
      switch (this.selectedTier) {
        case 1:
        case 2:
          return this.$t("MedicareBlueAdvantagePriceDataTable.30And100DaysSupply");
        case 5:
          return this.$t("MedicareBlueAdvantagePriceDataTable.30DaysSupply");
        default:
          return this.$t("MedicareBlueAdvantagePriceDataTable.30And90DaysSupply");
      }
    },
    getCopayText: function () {
      switch (this.selectedTier) {
        case 4:
        case 5:
          return this.$t("MedicareBlueAdvantagePriceDataTable.CoinsuranceText");
        default:
          return this.$t("MedicareBlueAdvantagePriceDataTable.CopayText");
      }
    },
  },
};
</script>

<style scoped>
.medicare-price-data-table {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.header-small {
  font-family: Roboto-Regular, sans-serif;
}

.heading {
  background-color: var(--LandingPage-CoverageInformationDisclaimer-BackgroundColor);
  color: var(--LandingPage-CoverageInformationDisclaimer-Color);
  padding: 16px;
}
</style>
